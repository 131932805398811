class Accordions {

	constructor() {
        // dom elements
        this.accordions = $('.accordion');
		this.accordionTitle = $('.accordion-title');
		
		if (this.accordions.length) {
			this.init();			
		}
	}

	init() {

        // on page load, open first accordion
        this.openFirst();

        // Handle toggling of accordion if clicked
		this.accordionTitle.on('click', (event) => {
			this.toggleAccordion(event);
		});

		// Handle toggling of accordion if enter key pressed
		this.accordionTitle.off('keypress').on('keypress', (event) => {
		    const keycode = (event.keyCode ? event.keyCode : event.which);

		    if( keycode == '13' || keycode == '32') {
		        this.toggleAccordion(event);
		    }
		});

	}

	toggleAccordion(event) {
		const accordion = $(event.currentTarget).closest('.accordion'),
			  accordionContent = accordion.children('.accordion-content'),
			  accordionTitle = accordion.children('.accordion-title'),
			  accordionArrow = $(event.currentTarget).children('.fas'),
			  openAccordions = accordion.siblings('.accordion.open');

        // close accordion
		if (accordion.hasClass('open')) {
			accordion.removeClass('open');
			accordionContent.slideUp(300);
			accordionTitle.attr('aria-expanded', false);
			accordionArrow.removeClass('fa-chevron-down').addClass('fa-chevron-right');
            $(event.currentTarget).blur();
		}
        // open accordion and close all others
		else {
			openAccordions.removeClass('open').find('.accordion-content').slideUp(300);
			openAccordions.children('.accordion-title').attr('aria-expanded', false);
			openAccordions.find('.accordion-title .fas').removeClass('fa-chevron-down').addClass('fa-chevron-right');

			accordion.addClass('open');
			accordionContent.slideDown('300', () => {
				accordionArrow.removeClass('fa-chevron-right').addClass('fa-chevron-down');
				accordionTitle.attr('aria-expanded', true);
				
				// check if any tables exist and initilize if not already
				const responsiveTable = accordionContent.find($('.responsive-table'));
				if (responsiveTable.length && !responsiveTable.hasClass('dataTable')) {
					responsiveTable.DataTable( {
					    responsive: true,
					    paging: false,
					    searching: false,
				        ordering:  false,
				        info: false,
				        autoWidth: false
					});
				}

				// tablet or down, scroll window to clicked accordion
				if (window.outerWidth < 991.98) {
					const headerHeight = $('.mobile-header').outerHeight(true);

					$('html, body').animate({
						scrollTop: accordion.offset().top - headerHeight
					}, 1000);
				}
			});
			
        }
    }
    
    
    openFirst() {
        const firstAccordion = this.accordions.first();       
        
		firstAccordion.addClass('open').find('.accordion-content').slideDown(300);
		firstAccordion.children('.accordion-title').attr('aria-expanded', true);
        firstAccordion.find('.accordion-title .fas').removeClass('fa-chevron-right').addClass('fa-chevron-down');

    }
}

class Alerts {
	
	constructor() {

		this.alert = $('.alert');
		this.alertCloseBtn = $('.close-alert-btn');

		if (this.alert.length) {
			this.init();			
		}
	}

	init() {

		// Handle Toggling Alert Click
		this.alertCloseBtn.on('click', (event) => {
			event.preventDefault();
			this.closeAlert(event);
		});

		this.checkStorage();
	}


	// check if they closed alert already and storage session id was set.  if so close id on page load`
	checkStorage() {
		$('.alert').each(function() {
			let id = $(this).attr('id');
			let alertSessionStorage = sessionStorage.getItem(id);
			// console.log(`id: ${id}, sessionID: ${alertSessionStorage}`);
			
			if (alertSessionStorage != null) {
				if (alertSessionStorage == 'closed') {
					$('#'+id+'').hide();
				}
				else {
					$('#'+id+'').show();
				}
			}
			else {
				$('#'+id+'').show();
			}
		});

	}
	
	// close alert and add session storage id
	closeAlert(event) {
		const alert = $(event.currentTarget).closest('.alert');
		const id = alert.attr('id');

		alert.slideUp(300);
		sessionStorage.setItem(id, 'closed');
	}

}

class ComparisonCards {

    constructor() {
        this.comparisonCards = $('.comparison-cards');        
        this.editMode = $('.sfPageEditor');        

        if (this.comparisonCards.length && !this.editMode.length) {
            this.init();
        }
        
    }
    
    init() {
        const self = this;
        
        // loop thru each slider and turn into slider if it doesn't have class .stack-cards
        this.comparisonCards.each(function() {
                        
            if (!$(this).hasClass('stack-cards')) {
                let slider = $(this).find('.slick-slider');
                let totalCards = slider.find('.comparison-card-item').length;
                
                if (totalCards > 3) {
                    self.initSlider(slider);
                }
                else {
                    slider.closest('.cards').addClass('less-than-3');
                }
            }
        });
            
    }
    
    initSlider(slider) {

        slider.slick({
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: false,
            arrows: true,
            nextArrow: '<span class="next-btn" tabindex="0"><span class="icon"><svg xmlns="http://www.w3.org/2000/svg" width="25.142" height="70.142" viewBox="0 0 25.142 70.142"><g class="slider-arrow-right" transform="translate(2.321 2.071)"><line class="Line_56" data-name="Line 56" x2="21" y2="33" transform="translate(-0.25 0)"/><line class="Line_57" data-name="Line 57" x1="21" y2="33" transform="translate(-0.25 33)"/></g></svg></span></span>',
            prevArrow: '<span class="prev-btn" tabindex="0"><span class="icon"><svg xmlns="http://www.w3.org/2000/svg" width="20.161" height="54.374" viewBox="0 0 20.161 54.374"><g class="slider-arrow-left" transform="translate(1164.273 1146.486) rotate(180)"><line class="Line_9" data-name="Line 9" x2="17.385" y2="25.799" transform="translate(1145.5 1093.5)"/><line class="Line_10" data-name="Line 10" y1="25.799" x2="17.385" transform="translate(1145.5 1119.299)"/></g></svg></span></span>',
            responsive: [

                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
            // adaptiveHeight: true
        });
    }
}

class FeaturedResources {

	constructor() {
		// dom elements
		this.resourceLinks = $('.featured-resource-item a');

		if (this.resourceLinks.length) {
			this.init();			
		}
	}

	init() {

		// Handle when hovering into any news image/title/btn
		this.resourceLinks.on({
		    mouseenter: (event) => {
		        this.addHoverState(event);
		    },
		    mouseleave: (event) => {
		        this.removeHoverState(event);
		    }
		});

		// Handle when focused into any news image/title/btn
		this.resourceLinks.on('focusin', (event) => {
	        this.addHoverState(event);
		}).on('focusout', (event) => {
	        this.removeHoverState(event);
		});

	}

	addHoverState(event) {
		$(event.currentTarget).closest('.featured-resource-item').addClass('hover');
	}

	removeHoverState(event) {
		$(event.currentTarget).closest('.featured-resource-item').removeClass('hover');
	}

}

class FeaturedStats {

    constructor() {
        this.stats = $('.featured-stat-item');
        this.statHeaders = $('.featured-stat-item .stat-header');

        if (this.stats.length) {
            this.init();
        }
    }

    init() {

        // on page load, calculate tallest headline and set each one equally
        this.getTallestHeadline();

        $(window).resize(()=> {
            this.getTallestHeadline();
        });
        
    }

    getTallestHeadline() {
        let tallest = 0;
        const stats = $('.featured-stat-item');
        
        this.statHeaders.css('height', 'auto'); // reset headers before calculating tallest
        stats.each( function() {
            let itemHeight = $(this).find('.stat-header').outerHeight(true);
            tallest = itemHeight > tallest ? itemHeight : tallest;
            // console.log(`itemHeight: ${itemHeight}, tallest: ${tallest}... tallest now is ${tallest}`);
        });

        // set all headlines to be equal heights
        this.statHeaders.css('height', tallest);
    }
}

class FeaturedRates {

    constructor() {
        this.rates = $('.featured-rate-item');
        this.rateHeaders = $('.featured-rate-item .rate-header');

        if (this.rates.length) {
            this.init();
        }
    }

    init() {

        // on page load, calculate tallest headline and set each one equally
        this.getTallestHeadline();

        $(window).resize(()=> {
            this.getTallestHeadline();
        });
        
    }

    getTallestHeadline() {
        let tallest = 0;
        const rates = $('.featured-rate-item');
        
        this.rateHeaders.css('height', 'auto'); // reset headers before calculating tallest
        rates.each( function() {
            let itemHeight = $(this).find('.rate-header').outerHeight(true);
            tallest = itemHeight > tallest ? itemHeight : tallest;
            // console.log(`itemHeight: ${itemHeight}, tallest: ${tallest}... tallest now is ${tallest}`);
        });

        // set all headlines to be equal heights
        this.rateHeaders.css('height', tallest);
    }
}

class FloatingFormLabel {

    constructor() {
        // dom elements
        this.formFields = $('.floating-form-label input, .floating-form-label select, .floating-form-label textarea');

        if (this.formFields.length) {
            this.init();            
        }
    }

    init() {

        // on page load check inputs for postbacks to style accordingly
        this.formFields.each(function() {
            $(this).val(''); 
            let field = $(this).closest('.field');
            
            if ($(this).val()) {
                field.addClass("not-empty");
            }
            else {
                field.removeClass("not-empty");
            }
        });

        // Handle when input gets focus
        this.formFields.on('focus', (event) => {
            this.hasFocus(event);   
        });

        // Handle when input loses focus
        this.formFields.on('blur', (event) => {
            this.checkIfEmpty(event);
        });
    }

    hasFocus(event) {
        const field = $(event.target).closest('.field');          
        field.addClass('active'); 
    }

    checkIfEmpty(event) {
        const field = $(event.target).closest('.field');

        if ($(event.target).val()) {
            field.addClass("not-empty");
        }
        else {
            field.removeClass("not-empty");
        }

        field.removeClass('active');  
    }


	

}

class Menu {

	constructor() {
		// dom elements
		this.body = $('body');
		this.mainMenu = $('.main-menu');
		this.siteHeader = $('header.site-header');
		this.toggleMobileMenuBtn = $('.toggle-mobile-menu .menu-btn');
		this.menuTier1LI = $('.main-menu li.tier1.has-children');
		this.menuTier1Link = $('.main-menu li.tier1.has-children > a');
		this.mobileMenuIcons = $('.main-menu li .icon');

		// variables/indicators
		this.breakpoint = 1199.98;  // bootstraps "lg"
		this.browserWidth = this.getBrowserWidth();

		this.init();
	}

	init() {

		// Handle rebinding elements if page resized
		this.bindMenu();

		$(window).resize( () => {
			this.bindMenu();
		});
	}

	// determines if desktop or mobile and binds appropriate menu elements
	bindMenu() {
		this.browserWidth = this.getBrowserWidth();

		if (this.browserWidth >= this.breakpoint) {
			this.unbindMobile();
			this.desktopHandlers();
		}
		else {
			this.unbindDesktop();
			this.mobileHandlers();
		}
	}

	desktopHandlers() {

        // Desktop Menu Hovered Over
        this.menuTier1LI.off('mouseenter mouseleave').on({
            mouseenter: (event) => {
            $(event.currentTarget).siblings('.hover').removeClass('hover').children('.mega-menu').stop().slideUp().removeClass('open');
                $(event.currentTarget).addClass('hover');
                $(event.currentTarget).children('.mega-menu').stop().addClass('open').slideDown(300);
            },
            mouseleave: (event) => {
                $(event.currentTarget).removeClass('hover');
                $(event.currentTarget).children('.mega-menu').stop().removeClass('open').slideUp(300);
            }
        });

        // Keyboard Used to focus
        this.menuTier1Link.on({
            focus: (event) => {
                const menuLI = $(event.currentTarget).parent('li.has-children');

                // close any open menus first
                menuLI.siblings('.has-children.hover').removeClass('hover').children('.mega-menu').stop().slideUp().removeClass('open');

                // now open focused menu item
                menuLI.addClass('hover').children('.mega-menu').stop().slideDown().addClass('open');
            }
		});
		
		// close menu if you focus anywhere besides menu
		$('a').on('focus', (event) => {			
			if (!$(event.target).closest('.main-menu').length) {
				this.menuTier1LI.removeClass('hover').children('.mega-menu').stop().slideUp().removeClass('open');
			}
        }); 
	}


	mobileHandlers() {
		
		// Mobile Menu Btn clicked
		this.toggleMobileMenuBtn.off('click').on('click',  (event) => {
			const keycode = (event.keyCode ? event.keyCode : event.which);

			$(event.currentTarget).find('.fas').toggleClass('fa-bars fa-times');
			this.toggleMobileMenu();
			$(event.currentTarget).blur();
			return false;
		});

		// Mobile Menu Btn Enter Key Pressed
		$('.toggle-mobile-menu').off('keypress').keypress( (event) => {
		    const keycode = (event.keyCode ? event.keyCode : event.which);

		    if( keycode == '13' || keycode == '32') {
		        this.toggleMobileMenuBtn.find('.fas').toggleClass('fa-bars fa-times');
				this.toggleMobileMenu(); 
				return false;
			}
		});

		// Mobile Item's Icon clicked to toggle submenu
		this.mobileMenuIcons.off('click').on('click',  (event) =>  {
			event.preventDefault();

			this.mobileSubMenu(event);
		});

		// Mobile Item's Icon trigered with keyboard 
		this.mobileMenuIcons.off('keypress').keypress( (e) => {
		    const keycode = (e.keyCode ? e.keyCode : e.which);

		    if( keycode == '13' || keycode == '32') {
		        this.mobileSubMenu(event);
		    }
		});
	}

	unbindDesktop() {
		$('.main-menu li.tier1.has-children').off('mouseenter mouseleave');
	}

	unbindMobile() {
		this.mobileMenuIcons.off('click');
		this.mobileMenuIcons.off('keypress');
	}

	toggleMobileMenu() {

		if (!$('body').hasClass('nav-open')) {
			this.openMobileMenu();
		}
		else {
			Menu.closeMobileMenu();
		}

		this.calculateMenuHeight();		
	}



	openMobileMenu() {
		// first close login and Search if they are open
        if ($('.toggle-mobile-login').hasClass('open')) {
            Login.closeLogin();
        }
        if ($('.site-search').hasClass('open')) {
            HeaderSearch.closeSearch();
        }
		
		this.body.addClass('nav-open');
		this.toggleMobileMenuBtn.addClass('open').attr('aria-expanded', true).children('.text').html('CLOSE');
		this.mainMenu.slideDown();
	}

	static closeMobileMenu() {
		$('body').removeClass('nav-open');
		$('.main-menu').slideUp();
		$('.menu-btn').removeClass('open');
		$('.toggle-mobile-menu .menu-btn').removeClass('open').attr('aria-expanded', false).children('.text').html('MENU');
		// close all menu's
		$('.main-menu li.open').removeClass('open hover').find('.icon').removeClass('fa-minus').addClass('fa-plus');
		$('.main-menu .dropdown').slideUp();
	}

	// Handles toggling a mobile menu item
	mobileSubMenu(event) {
		const clickedLI = $(event.target).closest('li');
		
		$(event.currentTarget).toggleClass('fa-plus fa-minus');
		clickedLI.toggleClass('open').children('.dropdown').slideToggle(300);

		// close open menu items
		clickedLI.siblings().removeClass('open').find('.dropdown').slideUp(300);
		clickedLI.siblings().find('.icon').removeClass('fa-minus').addClass('fa-plus');
	}

	// when mobile menu is open, calculate height of any alerts and header and set CSS so only menu is scrollable.  rest of site is unscrollable during menu open
	calculateMenuHeight() {
		const headerH = this.siteHeader.find('.mobile-header').outerHeight(true);
		let alertH = 0,
			totalH = 0;

		const alerts = $('.alert');
		if (alerts.length) {
			alerts.each( function() {
				alertH += $(this).outerHeight(true);
			});
		}
		totalH = headerH;

		this.siteHeader.css('max-height', 'calc(100vh - '+totalH+'px)');
	}
	
	resetMenu() {
		this.siteHeader.css('height', 'auto');
	}

	getBrowserWidth() {
		return window.innerWidth;
	}

}

class Login {

    constructor() {
        this.loginWrapper = $('header .login-wrapper');
        this.loginBtn = $('header .toggle-login-btn');
        this.mobileLoginBtn = $('header .toggle-mobile-login');
        this.loginBox = $('header .login-box');
        this.mobileLoginBox = $('header .mobile-login-box');
        this.breakpoint = 1199.98;  // bootstraps "lg"

        if (this.loginWrapper.length) {
            this.init();
        }
    }

    init() {

        this.moveLoginIfMobileHeader();
        $(window).resize(() => {
            this.moveLoginIfMobileHeader();
        });

        if (this.loginBtn.attr('href') && this.loginBtn.attr('href') != null) {
            // Handler to Toggle Mobile Login Box
            $('header .toggle-mobile-login').attr('href', $('header .toggle-login-btn').attr('href'));
            $('header .toggle-mobile-login').attr('target', $('header .toggle-login-btn').attr('target'));
            $('header .toggle-mobile-login').attr('aria-label', $('header .toggle-login-btn').attr('aria-label'));
        } else {
            // Handler to Toggle Desktop Login Box
            this.loginBtn.off('click').on('click', (event) => {
                event.preventDefault();
                this.toggleLoginBox();
                $(event.currentTarget).blur();
            });

            // Handler to Toggle Mobile Login Box
            this.mobileLoginBtn.off('click').on('click', (event) => {
                event.preventDefault();
                $(event.currentTarget).toggleClass('open');
                this.toggleLoginBox();
                $(event.currentTarget).blur();
            });

            // handle if user clicks outside of search box
            $(document).on('click', (event) => {
                let browserWidth = $(window).outerWidth();
            
                // if on desktop, did they click outside of button or login box
                if (browserWidth > this.breakpoint) {
                    if (!$(event.target).closest('header .login-wrapper').length) {
                        Login.closeLogin();
                    }
                }
                else {
                    // if on mobile header, did they click outside of button or login box
                    if ( (!$(event.target).closest('header .login-wrapper').length) && (!$(event.target).closest('header .toggle-mobile-login')) ) {
                        Login.closeLogin();
                    }
                }
            }); 
        }

        
        
    }

    // Desktop Login
    toggleLoginBox() {
        this.loginWrapper = $('header .login-wrapper');

        if (!this.loginWrapper.hasClass('open')) {
            this.openLogin();
        }
        else {
            Login.closeLogin();
        }
    }



    openLogin() {
        this.loginWrapper = $('header .login-wrapper');
        this.loginBox = $('header .login-box');   

        // first close Search and menu if they are open
        if ($('.site-search').hasClass('open')) {
            HeaderSearch.closeSearch();
        }
        if ($('body').hasClass('nav-open')) {
            Menu.closeMobileMenu();
        }        

        this.loginWrapper.addClass('open');
        this.loginBox.slideDown(300);
        $('header .login-box input').first().focus();
    }

    static closeLogin() {
        const loginWrapper = $('header .login-wrapper');
        const loginBox = $('header .login-box');
        const mobileLoginBtn = $('header .toggle-mobile-login');
        
        loginWrapper.removeClass('open');
        mobileLoginBtn.removeClass('open');
        loginBox.slideUp(300);
    }


    // if mobile header is showing, move login inside .mobile-header
    moveLoginIfMobileHeader() {
        let browserWidth = $(window).outerWidth();

        if (browserWidth < this.breakpoint) {
            if (!$('header .mobile-login-box .login-wrapper').length) {
                $('.utility-sub-menu .desktop-login .login-wrapper').appendTo('header .mobile-login-box');
                // console.log('moving login to mobile position');                
            }
        }
        else {
            if (!$('.utility-sub-menu .desktop-login .login-wrapper').length) {
                $('header .mobile-login-box .login-wrapper').appendTo('.utility-sub-menu .desktop-login');
                // console.log('moving login back to desktop position');                
            }
            
        }
    }
}

class HeaderSearch {

    constructor() {
        this.searchWrapper = $('header .site-search');
        this.toggleSearchBtn = $('header .site-search .toggle-search');
        this.searchBox = $('header .site-search .search-box');
        this.breakpoint = 1199.98;  // bootstraps "lg"

        if (this.searchWrapper.length) {
            this.init();
        }
    }

    init() {

        this.moveSearchIfMobileHeader();
        $(window).resize(() => {
            this.moveSearchIfMobileHeader();
        });

        // Handler to Toggle Search Box
        this.toggleSearchBtn.off('click').on('click', (event) => {
            event.preventDefault();
            this.toggleSearchBox();
            this.updateAriaLabel();
        });

        // handle if user clicks outside of search box
        $(document).off('click').on('click', (event) => {                    
            if (!$(event.target).closest('header .site-search').length) {
                HeaderSearch.closeSearch();
            }
         }); 
        
    }

    toggleSearchBox() {
        if (!this.searchWrapper.hasClass('open')) {
            this.openSearch();
        }
        else {
            HeaderSearch.closeSearch();
        }
    }

    openSearch() {
        // first close login and menu if they are open
        if ($('.toggle-mobile-login').hasClass('open')) {
            Login.closeLogin();
        }
        if ($('body').hasClass('nav-open')) {
            Menu.closeMobileMenu();
        }
        
        this.searchWrapper.addClass('open');
        $('header .site-search .search-box input').focus();
        $('header').addClass('search-open');
    }

    updateAriaLabel() {
        const isOpen = this.searchWrapper.hasClass('open');
        const ariaLabel = isOpen ? 'click here to close site search' : 'click here to open site search';
        this.toggleSearchBtn.attr('aria-label', ariaLabel);
    }

    static closeSearch() {
        $('header .site-search').removeClass('open');
        $('header').removeClass('search-open');
    }

    moveSearchIfMobileHeader() {
        // if mobile header is showing, move search inside .mobile-header
        let browserWidth = $(window).outerWidth();
        // console.log(`browser: ${browserWidth}, breakpoint: ${this.breakpoint}`);

        if (browserWidth < this.breakpoint) {
            if (!$('.mobile-header .mobile-search .site-search').length) {
                $('header .site-search').appendTo('.mobile-header .mobile-search');
                // console.log('moving search to mobile position');
            }
        }
        else {
            if (!$('.utlity-nav .desktop-site-search .site-search').length) {
                $('.mobile-header .mobile-search .site-search').appendTo('.utility-nav .desktop-site-search');
                // console.log('moving search back to desktop position');
            }
            
        }
    }
}

/**
 * Lazy loads images that are set up properly.
 * For img tags: 
 *      <img
            class="img-load-lazy"
            src="small-placeholder.jpeg"
			data-src="full-sized-actual-img.jpg"
			data-srcset="full-sized-actual-img.jpg 2x, mobile-sized-actual-img.jpg 1x"
            alt=""
        >
            
    For BG Images: <div class="lazy-load-bg" style="background-image: url('/my-image.jpeg')"></div> 

    more info: https://developers.google.com/web/fundamentals/performance/lazy-loading-guidance/images-and-video
 */
class ImageLoader {

    constructor(options = {}) {
        this.queue = [];
        this.backgroundQueue = [];
        this.lazyImageObserver = null;
        this.lazyBgObserver = null;
        this.options = options;
    }

    initialize() {
        this.getImagesToLazyLoad();

        if (!("IntersectionObserver" in window)) {
            this.abort();
            return;
        }

        this.onContentLoaded();
        this.setGenericPlaceholder();

        this.queue.forEach((lazyImage) => {
            this.lazyImageObserver.observe(lazyImage);
        });

        this.backgroundQueue.forEach((lazyBg) => {
            this.lazyBgObserver.observe(lazyBg);
        });
    }

    getImagesToLazyLoad() {
        this.queue = document.querySelectorAll('.img-load-lazy');
        this.backgroundQueue = document.querySelectorAll('.lazy-load-bg');
    }

    setGenericPlaceholder() {
        if (this.options.genericImagePlaceholder) {
            this.queue.forEach((img) => {
                img.setAttribute('src', this.options.genericImagePlaceholder);
            });
        }

        if (this.options.genericBgPlaceholder) {
            this.backgroundQueue.forEach((bg) => {
                bg.style.backgroundColor = this.options.genericBgPlaceholder;
            });
        }
    }

    onContentLoaded() {
        this.lazyImageObserver = new IntersectionObserver((entries, observer) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    let lazyImage = entry.target;
                    lazyImage.src = lazyImage.dataset.src;
                    lazyImage.srcset = lazyImage.dataset.srcset;
                    lazyImage.classList.remove('img-load-lazy');
                    this.lazyImageObserver.unobserve(lazyImage);
                }
            });
        });

        this.lazyBgObserver = new IntersectionObserver((entries, observer) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.remove('lazy-load-bg');
                    this.lazyBgObserver.unobserve(entry.target);
                }
            });
        });
    }

    abort() {
        for (let i = 0; i < this.queue.length; i++) {
            this.queue[i].setAttribute('src', this.queue[i].getAttribute('data-src'));
            this.queue[i].classList.remove('img-load-lazy');
        }

        for (let i = 0; i < this.backgroundQueue.length; i++) {
            this.backgroundQueue[i].classList.remove('lazy-load-bg');
        }
    }

}

class IsElementInView {

    static main() {        
        
        // add any modules to this list to add class .isVisible when they are in view for animations
        let animatedModules = [
            '.solutions-hero',
            '.featured-resources',
            '.section-title-with-pulse',
            '.comparison-cards',
            '.featured-video-item',
            '.simple-form-callout-item'
        ];

        // on page load check if modules in view
        loopThruModules();
        
        // on scroll check if modules in view
        $(window).on('resize scroll', function() {
            loopThruModules();
        });

        
        function loopThruModules() {
            // loop through array of module classes/id's, and if visible add class "isVisible"
            $.each( animatedModules, function( index, myModule ){
                if ($(myModule).length) {
                    // console.log(myModule);
                    if (isInViewport(myModule)) {
                        // console.log(myModule+' - is now in view');
                        $(myModule).addClass('isVisible');
                    }
                }
                
            });
        }
        
        function isInViewport(myModule) {
            let elementTop = $(myModule).offset().top;
            let elementBottom = elementTop + $(myModule).outerHeight();

            let viewportTop = $(window).scrollTop();
            let viewportBottom = viewportTop + $(window).height();

            // returns true as soon as element starts to become in view
            return elementBottom > viewportTop && elementTop < viewportBottom;
        }


    }

}

function MapMarker(model, map, bounds, onMarkerClick, allInfoWindows) {
    let marker = null;
    let locations = $('.locations-listings article.enable-on-map');
    let greatestMarkerDistance = -1;

    const options = {};
    options.position = new google.maps.LatLng(model.lat, model.long);
    options.map = map;

    if (model.icon) {
        options.icon = model.icon;
        options.optimized = true;
    }

    marker = new google.maps.Marker(options);
    
    bounds.extend(marker.position);
    map.fitBounds(bounds);

    function toRad(value) {
        return value * Math.PI / 180;
    }
    
    function calcDistance(lat1, lng1, lat2, lng2) {
        var earth = 6371, //km
            dLat = toRad(lat2 - lat1),
            dLng = toRad(lng2 - lng1);

        lat1 = toRad(lat1);
        lat2 = toRad(lat2);

        var a = Math.sin(dLat/2) * Math.sin(dLat/2) + Math.sin(dLng/2) * Math.sin(dLng/2) * Math.cos(lat1) * Math.cos(lat2);
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
        var distance = (earth * c) / 1.609; //convert from km to miles

        return distance;
    }

    if (locations.length < 6) {
        // check greatest distance between markers shown
        for (var i = 0; i < locations.length; i++) {
            for (var j = 1; j < locations.length; j++) {
                var lat1 = locations[i].getAttribute("data-lat"),
                    lng1 = locations[i].getAttribute("data-lng"),
                    lat2 = locations[j].getAttribute("data-lat"),
                    lng2 = locations[j].getAttribute("data-lng"),
                    distance;

                distance = calcDistance(lat1, lng1, lat2, lng2);

                if (distance > greatestMarkerDistance) {
                    greatestMarkerDistance = distance;
                }
            }
        }

        // if the greatest distance is less than half a mile zoom out 
        if (greatestMarkerDistance <= 0.5) {
            var listener = google.maps.event.addListener(map, "idle", function() { 
                if (map.getZoom() > 15) map.setZoom(15); 
                google.maps.event.removeListener(listener); 
            });
        }
    }

    let link;
    if (model.link != null && model.link != "") {
        link = `<a href="${model.link}">More Info</a><br/><br/>`;
    } else {
        link = "";
    }
    if (model.hoursets) {
        model.hoursets.each(function () {
            var title = $(this).find('.hourset-title').text();
            var hours = $('.hour', this);

            if (hours) {
                hours.each(function () {
                    var startDay = $(this).find('.start-day').text();
                    var endDay = $(this).find('.end-day').text();
                    var openTime = $(this).find('.open-time').text();
                    var closeTime = $(this).find('.close-time').text();
                });
            }
        });
    }
    let infowindow = new google.maps.InfoWindow({        
        content: `
            <div class="info-window">
                <h3 class="location-title">${model.name}</h3>
                <div class="bankAddress">
                    <p>${model.street}</p>
                    <p>${model.cityStateZip}</p>
                </div>
                <div class="ctas"> 
                    <a href="${model.link}" title="See Details About Adairsville - Adairsville Hwy">Learn More </a> 
                    <a href="https://www.google.com/maps/dir/current+location/${model.address}" class="speedbump-link" role="button" title="Get Directions to ${model.name}" target="_blank">Directions </a> 
                </div>
            </div>
        `
    });

    // need to save instance of each infoWindow so they can be closed
    allInfoWindows.push(infowindow);

    marker.addListener('click', () => {
        // close all infoWindows before opening new one
        for (let i = 0; i < allInfoWindows.length; i++) {
            allInfoWindows[i].close();
        }
        
        infowindow.open(map, marker);
        onMarkerClick(model.id, marker);
    });

    this.id = model.id;

    this.closeWindow = function () {
        $('#' + this.id).removeClass('active');
        infowindow.close();
    };

    this.openWindow = function () {
        infowindow.open(map, marker);
    };
}

/*
    :: Custom Google Map
*/

class GoogleMap {

    constructor(options) {
        this.locations = options.locations || [/* {address: string || [lat,lon], name: string, link: string, icon: string(img src)}*/];
        this.container = options.container || '#not_available';
        this.map = null;
        this.mapBounds = null;
        this.markers = [];
        this.styling = options.styling || false;
        this.onMarkerClick = options.onMarkerClick || function () { };
        this.allInfoWindows = [];
        this.searchedLocation = options.searchedLocation;
        this.searchedMarker = null;
        this.icon = options.icon;
    }

    main() {
        this.container = document.querySelector(this.container);

        if (!this.container) {
            return;
        }

        this.mapBounds = new google.maps.LatLngBounds();

        // add pin for searched location to bounds
        if (this.searchedLocation.position) {
            this.mapBounds.extend(this.searchedLocation.position);
        }

        this.map = new google.maps.Map(this.container, {
            disableDefaultUI: true,
            zoomControl: true,
            gestureHandling: 'cooperative',
            styles: this.styling
        });

        this.map.fitBounds(this.mapBounds);

        // Initialize google maps bounding box.
        if (this.locations.length == 0 && this.searchedLocation.position) {
            var zoomListener = google.maps.event.addListener(this.map, "idle", () => { 
                this.map.setZoom(13); 
                google.maps.event.removeListener(zoomListener); 
            });
        }
        else {
            let listener = google.maps.event.addListener(this.map, "idle", () => {
                this.map.fitBounds(this.mapBounds);
                google.maps.event.removeListener(listener);
            });
        }

        for (let location of this.locations) {
            this.setupMarker(location, this.allInfoWindows, location.id);
            this.linkMarker(location.id, this.markers);
        }

        // drop pin at searched location (if it exists)
        if (this.searchedLocation.position) {
            this.searchedMarker = new google.maps.Marker({
                position: this.searchedLocation.position,
                map: this.map,
                animation: google.maps.Animation.DROP
            });
        }
    }

    linkMarker(id, markers) {
        $('.location-item.enable-on-map').off('click');

        $('.location-item.enable-on-map').on('click', function () {
            let index = $('article.enable-on-map').index($(this));

            if ($(this).hasClass('active')) {
                $(this).removeClass('active');
            }

            else {
                $('.location-item.active').removeClass('active');
                $(this).addClass('active');
            }

            // close all open infowindows
            for (let marker of markers) {
                marker.closeWindow();
            }

            // open infowindow for this location
            $(this).addClass('active');
            markers[index].openWindow();
        });
    }

    // Convert address string to lat long object
    setupMarker(location, allInfoWindows, locationId) {
        // Lat and Long was provided for the location address
        if (Array.isArray(location.address)) {
            this.makeMarker(location, location.address[0], location.address[1], allInfoWindows, locationId);
            return;
        }

        // Address string was provided
        if (typeof location.address == "string") {
            const geocoder = new google.maps.Geocoder();
            const geoModel = {
                'address': location.address
            };

            geocoder.geocode(geoModel, (results, status) => {
                if (status != google.maps.GeocoderStatus.OK) {
                    console.warn(`Google maps Geocode: Can't convert ${location.address} to lat/lon: ${status}`);
                    return;
                }

                this.makeMarker(location, results[0].geometry.location.lat(), results[0].geometry.location.lng(), allInfoWindows, locationId);
            });

            return;
        }

        // Error, or nothing was provided
        console.warn(`Map Marker address for ${location.name} is invalid or not set`);
    }

    makeMarker(location, lat, long, allInfoWindows, locationId, icon) {
        let marker = null;
        // let image = {
        //     url: originURL + '/library/img/locationsIcons/mapIcons/blank.png'
        // };

        marker = new MapMarker({
            name: location.name,
            address: location.address,
            street: location.street,
            cityStateZip: location.cityStateZip,
            link: location.link,
            hoursets: location.hoursets,
            icon: location.icon,
            lat: lat,
            long: long,
            id: locationId
        }, this.map, this.mapBounds, this.onMarkerClick, allInfoWindows);

        this.markers.push(marker);
    }

}

class Locations {

    static displayLocations() {
        const locations = $('.enable-on-map');
        const map = $('#map');
        //var autocomplete = new google.maps.places.Autocomplete(document.getElementById('locationInput'));

        let pinOptions = {},
            lat,
            lng;

        if (!map.length || !locations.length) {
            return;
        }

        let data = [],
            address,
            street,
            cityStateZip,
            icon;

		var branchImage = new google.maps.MarkerImage(
			'/ResourcePackages/ST/library/img/icons/mapIcons/branches.png',
			new google.maps.Size(37, 52),
			new google.maps.Point(0, 0),
			new google.maps.Point(17, 46)
		);

        var atmImage = new google.maps.MarkerImage(
            '/ResourcePackages/ST/library/img/icons/mapIcons/georgiaUnitedAtms.png',
            new google.maps.Size(38, 51),
            new google.maps.Point(0, 0),
            new google.maps.Point(17, 46)
        );        
        
        var liveTellerImage = new google.maps.MarkerImage(
            '/ResourcePackages/ST/library/img/icons/mapIcons/atmLiveTeller.png',
            new google.maps.Size(37, 53),
            new google.maps.Point(0, 0),
            new google.maps.Point(17, 46)
		);
		
		var blankImage = new google.maps.MarkerImage(
            '/ResourcePackages/ST/library/img/icons/mapIcons/blank.png',
            new google.maps.Size(34, 46),
            new google.maps.Point(0, 0),
            new google.maps.Point(17, 46)
        );
        
        locations.each(function () {
            // set the initial order
            let index = this.id.split('-')[1];
            index = index - 1;
            $(this).css('order', index);

            
            var arrTitles = $(this)
            .find('.hours .label')
            .map(function() {
                return $(this).text();
            })
            .get();

            if(arrTitles[0] == "Drive-Up Hours:" && arrTitles.length > 1){
                $(this).find('.hours').addClass('reverse');
            }


            // If a lat and long was provided, use that.
            // If not, then use the address string
            if ($(this).attr('data-lat') && $(this).attr('data-lng')) {
                address = [$(this).attr('data-lat'), $(this).attr('data-lng')];
            } else {
                address = $(this).attr('data-address');
            }            

            let locOptions = $(this).find('.options').text().toLowerCase().trim();
            if (locOptions.indexOf('branch') >= 0) {
                icon = branchImage;
            } else if (locOptions.includes('teller') >= 0 || locOptions.toUpperCase().includes("ATM+") || locOptions.toUpperCase().includes("ATM PLUS") || locOptions.toUpperCase().includes('UNITED') >= 0 ) {
                icon = liveTellerImage;
            } else {
                icon = blankImage;
            }

            street = $(this).attr('data-street');
            cityStateZip = $(this).attr('data-citystatezip');

            data.push({
                address: address,
                street: street,
                cityStateZip: cityStateZip,
                name: $(this).find('.location-title').text(),
                link: $(this).find('.location-title a').attr('href'),
                hoursets: $('.hourset', this),
                id: $(this).data('id'),
                icon: icon
            });
        });

        var locationsMap = new GoogleMap({
            locations: data,
            container: '#map',
            onMarkerClick: (id, marker) => {
                $('.location-item.active').removeClass('active');
                $('#location-' + id).addClass('active');

                if ( !$('.locations').hasClass('show-locations')) {
                    $('.locations').addClass('show-locations');
                    calculateItemPositions();
                }

                // animate locations to item selected
                let itemTop = $('#location-' + id).attr('data-top');
                $('.locations-pane').animate({
                    scrollTop: itemTop - 30
                }, 400);
            },
            searchedLocation: pinOptions
        });

        locationsMap.main();
        filterLocations();

        $('#locationInput').on('change', event => {
            if (event.target.value === "") {
                //autocomplete.set('place', {});
                resetMap();
            }
        });
        
        $('#locationInput').keypress( (event) => {
            const keycode = (event.keyCode ? event.keyCode : event.which);
            
            if( keycode == '13') {
                event.preventDefault();
                if (event.target.value === "") {
                    //autocomplete.set('place', {});
                    resetMap();
                }
            }                    
        });
        
        // on filter type change, update locations
        $('.filter-by-types .js-filter-type-item input').on('change', function (e) {
            $(this).closest('.js-filter-type-item').toggleClass('checked');
            filterLocations($(this));
            updateFilterSelectboxText();
        });

        // clear filters clicked
        $('.js-clear-filters-btn').on('click', (e) => {
            clearFilters();
            $(e.currentTarget).hide();

        });

        // custom filter type selectbox
        $('.filter-by-types .js-custom-select-box').on('click', (e) => {
            toggleFilterSelectbox(e);
        });
        $('.filter-by-types .js-custom-select-box').on('keypress', (e) => {
            e.preventDefault();
            let keycode = e.code || e.which;
            
            if (keycode === 13 || keycode === "Enter" || keycode === 32 || keycode === "Space") {
                toggleFilterSelectbox(e);
            }
        });

        // close filter when clicked outside of dropdown
        $(document).on('click', function(e) {
            const selectbox = $('.filter-by-types .js-custom-select-box');
            const arrow = selectbox.find('.arrow .fas');
            const dropdown = selectbox.siblings('.dropdown');
            
            if (!$(e.target).closest('.filter-by-types').length) {
                selectbox.removeClass('open');
                arrow.removeClass('fa-chevron-up').addClass('fa-chevron-down');
                dropdown.slideUp(300);
            }
        });
        $('.locations-template a').on('focus', function(e) {
            const selectbox = $('.filter-by-types .js-custom-select-box');
            const arrow = selectbox.find('.arrow .fas');
            const dropdown = selectbox.siblings('.dropdown');
            
            if (!$(e.target).closest('.filter-by-types').length) {
                selectbox.removeClass('open');
                arrow.removeClass('fa-chevron-up').addClass('fa-chevron-down');
                dropdown.slideUp(300);
            }            
        });
        
        // utilize Google Places API to filter locations within a radius
        if ($('#map').length != 0) {

            $('.apply-filters-btn').on('click', function (e) {
                e.preventDefault();
                filterLocations($(this));
            });

            placesAutocomplete();
        }

        // open/close custom filter selectbox
        function toggleFilterSelectbox(e) {
            const selectbox = $(e.currentTarget);
            const arrow = selectbox.find('.arrow .fas');
            const dropdown = selectbox.siblings('.dropdown');

            if (!selectbox.hasClass('open')) {
                selectbox.addClass('open');
                arrow.removeClass('fa-chevron-down').addClass('fa-chevron-up');
                dropdown.slideDown(300);
            }
            else {
                selectbox.removeClass('open');
                arrow.removeClass('fa-chevron-up').addClass('fa-chevron-down');
                dropdown.slideUp(300);
            }            
        }

        // update custom filter selectbox text based off selections made
        function updateFilterSelectboxText() {
            const filterTypes = $('.filter-by-types .js-filter-type-item input:checked');
            const selectboxText = $('.filter-by-types .js-custom-select-box .filter-text');
            const clearFiltersBtn = $('.js-clear-filters-btn');
            
            if (!filterTypes.length) {
                selectboxText.html("Select Filter(s)");
                clearFiltersBtn.hide();
            }
            else if (filterTypes.length == 1) {
                selectboxText.html(filterTypes.siblings('.custom-control-label').html());
                clearFiltersBtn.css('display', 'block');
            }
            else if (filterTypes.length > 1) {
                selectboxText.html('('+filterTypes.length+') Filters Selected');
                clearFiltersBtn.css('display', 'block');
            }
        }

        function resetMap() {
            // reset location data, show all locations
            pinOptions = {};
            lat = '';
            lat = '';
            document.querySelectorAll('.location-item').forEach(location => {
                // look at the call parameters before using the
                // showLocation() method
                showLocation($(location),'',true);
            });

            reloadMap();
            filterLocations();
        }

        function reloadMap() {
            if (!map.length || !locations.length) {
                return;
            }

            // reset shown locations based on filter
            data = [];
            let address,
                street,
                cityStateZip,
                icon;

            $('.enable-on-map').each(function () {
                // If a lat and long was provided, use that.
                // If not, then use the address string
                if ($(this).attr('data-lat') && $(this).attr('data-lng')) {
                    address = [$(this).attr('data-lat'), $(this).attr('data-lng')];
                } else {
                    address = $(this).attr('data-address');
                }

                let locOptions = $(this).find('.options').text().toLowerCase().trim();
                if (locOptions.indexOf('branch') >= 0) {
					icon = branchImage;
				}
                else if (locOptions.includes('teller') >= 0 || locOptions.toUpperCase().includes("ATM+") || locOptions.toUpperCase().includes("ATM PLUS") || locOptions.toUpperCase().includes('UNITED') >= 0 ) {
					icon = liveTellerImage;
				} 
                else {
					icon = blankImage;
				}

                street = $(this).attr('data-street');
                cityStateZip = $(this).attr('data-citystatezip');

                data.push({
                    address: address,
                    street: street,
                    cityStateZip: cityStateZip,
                    name: $(this).find('.location-title').text(),
                    link: $(this).find('.location-title a').attr('href'),
                    hoursets: $('.hourset', this),
                    id: $(this).data('id'),
                    icon: icon
                });
            });

            locationsMap = new GoogleMap({
                locations: data,
                container: '#map',
                onMarkerClick: (id, marker) => {
                    $('.location-item.active').removeClass('active');
                    $('#location-' + id).addClass('active');
                    
                    if ( !$('.locations').hasClass('show-locations')) {
                        $('.locations').addClass('show-locations');
                        calculateItemPositions();
                    }                    

                    // animate locations to item selected
                    let itemTop = $('#location-' + id).attr('data-top');
                    $('.locations-pane').animate({
                        scrollTop: itemTop - 30
                    }, 400);
                },
                searchedLocation: pinOptions
            });

            locationsMap.main();
        }

        function hideLocation(item) {
            item.css('display', 'none');
            item.removeClass('enable-on-map');
        }

        /**
         * showLocation(item, distance, reset)
         * @param {Element} item 
         * @param {~String} distance 
         * @param {~Boolean} reset 
         */
        function showLocation(item, distance, reset) {
            item.css('display', 'flex');
            item.addClass('enable-on-map');

            // if you're resetting the map, reset the distance
            // or it will mess with this filter implementation
            if (reset) {
                distance = null;
                item.find('.distance').html('');
                item.data("distance", distance);
            }

            if (distance) {
                distance = distance.toFixed(2);
                item.find('.distance').show().html(distance + ' miles');
                item.data("distance", distance);
            }
        }

        function filterLocations() {
            const filterTypes = $('.filter-by-types input:checked');
            const showRadius = $('#show-radius').length ? $('#show-radius').val() : 20;
            
            $(".location-item").each(function () {
                var options = $(this).find(".bankInfo .options").text().toLowerCase().trim(),
                    lat2 = $(this).attr("data-lat"),
                    lng2 = $(this).attr("data-lng"),
                    show = false,
                    distance = null;

                // check if location matches any filter type selected
                filterTypes.each(function() {
                    let filterVal = $(this).val().toLowerCase();
    
                    // console.log(`options: ${options},       filterVal: ${filterVal}`);
                    if ( options.indexOf(filterVal) >= 0 ) {
                        show = true;
                    }
                });
                
                if (!filterTypes.length) {
                    show = true;
                }

                if (lat && lng) {
                    distance = calcDistance(lat, lng, lat2, lng2);

                    // only show location if it is in the selected radius IF SEARCH TERM PRESENT
                    if ($('#locationInput').val() !== '') {
                        if (distance > showRadius) {
                            show = false;
                        }
                    } else {
                        show = true;
                    }
                }

                if (show && distance) {
                    showLocation($(this), distance);
                }
                else if (show && !distance) {
                    showLocation($(this));
                }
                else {
                    hideLocation($(this));
                }
            });

            if (locationsMap.searchedLocation) {
                // sort shown locations by distance
                sortLocations();
            }

            reloadMap();

            // don't show location items on page load
            // if (initialPageLoad != true) {
            // }
            // Check if any locations were found.  If so, show items
            if ($('.locations-listings .location-item.enable-on-map').length > 0) {
                $('.locations').addClass('show-locations');                
                $('.locations .message-box').removeClass('show-no-locations');
                $('.clear-filters-btn').css('display', 'block');
            }
            else {
                $('.locations').removeClass('show-locations');                
                $('.locations .message-box').addClass('show-no-locations');
                $('.clear-filters-btn').hide();
            }


            calculateItemPositions();
        }

        function toRad(value) {
            return value * Math.PI / 180;
        }

        function calcDistance(lat1, lng1, lat2, lng2) {
            var earth = 6371, //km
                dLat = toRad(lat2 - lat1),
                dLng = toRad(lng2 - lng1);

            lat1 = toRad(lat1);
            lat2 = toRad(lat2);

            var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.sin(dLng / 2) * Math.sin(dLng / 2) * Math.cos(lat1) * Math.cos(lat2);
            var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
            var distance = (earth * c) / 1.609; //convert from km to miles

            return distance;
        }

        function sortLocations() {
            var locations = [];

            // create array of shown articles so we can sort it
            $('article.enable-on-map').each(function () {
                locations.push($(this));
            });

            // sort locations by distance in ascending order
            locations.sort(function (a, b) {
                return a.data("distance") - b.data("distance");
            });

            // iterate through sorted locations and order them on the page
            for (let i = 0; i < locations.length; i++) {
                locations[i].css('order', i);
            }
        }

        function calculateItemPositions() {
            locations.each(function () {
                // save items position in list as an attribute to use for marker clicks
                $(this).attr('data-top', $(this).position().top);
            });
        }


       
        /* Select 1st google Autocomplete on Enter */        
        function selectFirstOnEnter(input) {  // store the original event binding function
			var _addEventListener = (input.addEventListener) ? input.addEventListener : input.attachEvent;
			function addEventListenerWrapper(type, listener) {  // Simulate a 'down arrow' keypress on hitting 'return' when no pac suggestion is selected, and then trigger the original listener.
				if (type == "keydown") { 
					var orig_listener = listener;
					listener = function(event) {
						var suggestion_selected = $(".pac-item-selected").length > 0;
						if (event.which == 13 && !suggestion_selected) { 
							var simulated_downarrow = $.Event("keydown", {keyCode: 40, which: 40}); 
							orig_listener.apply(input, [simulated_downarrow]); 
						}
						orig_listener.apply(input, [event]);
					};
				}
				_addEventListener.apply(input, [type, listener]); // add the modified listener
			}
			if (input.addEventListener) { 
				input.addEventListener = addEventListenerWrapper; 
			} else if (input.attachEvent) { 
				input.attachEvent = addEventListenerWrapper; 
			}
		}
        
        

        function placesAutocomplete() {
            var furthestWest = 0,
			    furthestEast = 0,
			    furthestSouth = 0,
			    furthestNorth = 0,
                pinBuffer = 48280.3,                
                earth = 6378.137,  //radius of the earth in kilometer
                pi = Math.PI,
                m = (1 / ((2 * pi / 360) * earth)) / 1000;  //1 meter in degree

            // lat = north/south, lon =

			// Get bounding box values for autcomplete restriction
			$('.location-item').each(function() {
				if ($(this).attr('data-lat') && $(this).attr('data-lat')) {
					const lat = parseFloat($(this).attr('data-lat'));
					const lon = parseFloat($(this).attr('data-lng'));
	
					if (!furthestNorth || lat > furthestNorth) {
						furthestNorth = lat + (pinBuffer * m); // add 30mile (48280.3m) buffer from point
					}
                    
					if (!furthestSouth || lat < furthestSouth) {
                        furthestSouth = lat - (pinBuffer * m); // add 30mile (48280.3m) buffer from point
					}
                    
					if (!furthestWest || lon < furthestWest) {
                        furthestWest = lon - (pinBuffer * m) / Math.cos(lat * (pi / 180));
					}
					if (!furthestEast || lon > furthestEast) {
                        furthestEast = lon + (pinBuffer * m) / Math.cos(lat * (pi / 180));
					}
				}
			});
            
			var b1 = new google.maps.LatLng(furthestSouth, furthestWest );
			var b2 = new google.maps.LatLng(furthestNorth, furthestEast );
			var lookupBounds = new google.maps.LatLngBounds( b1, b2 );
            
			var options = {
				bounds: lookupBounds,
				strictBounds: true,
                types: ["(regions)"],
                componentRestrictions: {country: "us"}
			};
            
            var autocomplete = new google.maps.places.Autocomplete(document.getElementById('locationInput'), options);
            
            google.maps.event.addListener(autocomplete, 'place_changed', function () {
                let place = autocomplete.getPlace();

                if (place.geometry !== undefined) {
                    lat = place.geometry.location.lat();
                    lng = place.geometry.location.lng();

                    pinOptions = {};
                    pinOptions.position = new google.maps.LatLng(lat, lng);

                    // filter through locations to only show those in selected filter
                    filterLocations();

                    $('.locations').addClass('show-locations');
                }
                $('.js-clear-filters-btn').css('display', 'block');

            });

            selectFirstOnEnter(document.getElementById('locationInput'));
        }

        function clearFilters() {                        
            $('.js-filter-type-item input').prop('checked', false);
            $('.filter-by-types .js-custom-select-box .filter-text').html('Select Filter(s)');
            $('.filter-by-types .js-custom-select-box').removeClass('open').find('.dropdown').slideUp(300);
            $('#show-radius:second').prop('selected',true);
            
            // clear autocomplete
            $('#locationInput').blur().val('');

            $('.clear-filters-btn').hide();
            placesAutocomplete();
            filterLocations();
            reloadMap();
        }
    }
}

class PolyfillHelpers {

    static main() {
        
        // support for .forEach() in IE11
        if (window.NodeList && !NodeList.prototype.forEach) {
            NodeList.prototype.forEach = Array.prototype.forEach;
        }        

        // support for .closest in IE11
        if (!Element.prototype.matches) {
            Element.prototype.matches =
              Element.prototype.msMatchesSelector ||
              Element.prototype.webkitMatchesSelector;
          }
          if (!Element.prototype.closest) {
            Element.prototype.closest = function(s) {
              var el = this;
              do {
                if (Element.prototype.matches.call(el, s)) return el;
                el = el.parentElement || el.parentNode;
              } while (el !== null && el.nodeType === 1);
              return null;
            };
          }
    }
}

class ResponsiveDatatables {
	constructor() {
        this.responsiveTable = $('.responsive-table:visible');
        this.tableWrapper = $('.table-wrapper');
        this.tableHeadline = $('.table-wrapper .table-headline');
        this.editMode = $('.sfPageEditor');

		if (this.responsiveTable.length  && !this.editMode.length) {
            this.init();
        }
        
        if (this.tableWrapper.length  && !this.editMode.length) {
            this.toggleTable();
        }
	}
    
	init() {
        
        this.initializeDatatables(this.responsiveTable);       
        
        if (this.tableHeadline.length) {
            this.toggleTable();
        }

        $(window).resize(() => {
            if (this.tableHeadline.length) {
                this.toggleTable();
            }
        });
    }

    initializeDatatables(table) {
        // Initialize tables with dataTables plugin https://datatables.net/
		table.DataTable( {
            responsive: true,
		    paging: false,
		    searching: false,
	        ordering:  false,
	        info: false,
	        autoWidth: false
        });    
    }
    
    // if on mobile, loop thru each table header and bind clicking to toggle tables
    toggleTable() {
        self = this;
        let browserWidth = $(window).outerWidth();

        if (browserWidth < 991.98) {
            this.tableHeadline.each(function() {
                let toggleTableIcon = $(this).children('.mobile-icon');
                $(this).closest('.table-wrapper').find('.table-container').hide(); // if a table header exists, close all tables on mobile

                $(this).off('click').on('click', function() {
                    let closestTable = $(this).siblings('.table-container');

                    closestTable.slideToggle(300, function() {
                        self.initializeDatatables(closestTable.children('.responsive-table'));
                        toggleTableIcon.toggleClass('fa-chevron-right fa-chevron-down');
                    });
                });

            });
        }
    }

}

class ScrollToTop {

    static main() {
        
    	// Scroll To Top
    	$('.scrollToTop').on('click', function(){
    	    $('html, body').stop().animate({scrollTop: 0}, 500, 'linear');
    	    return false;
    	});

       scrollToTopButton();

    	// Fade in/out Scroll To Top button after 500px
    	function scrollToTopButton() {
    	    var button  = $('.scrollToTop'), 
    	        view = $(window),
    	        timeoutKey = -1;

    	    // page load
    	    if (view.scrollTop() > 500) {
    	        button.fadeIn();
    	    }
    	    else {
    	        button.fadeOut();
    	    }

    	    $(document).on('scroll', function() {
    	        if(timeoutKey) {
    	            window.clearTimeout(timeoutKey);
    	        }

    	        timeoutKey = window.setTimeout(function(){
    	            if (view.scrollTop() > 500) {
    	                button.fadeIn();
    	            }
    	            else {
    	                button.fadeOut();
    	            }
    	        }, 100);
    	    });
    	}
	    
    }

}

class SliderCallout {

    constructor() {
        this.sliderWrapper = $('.slider-callout');
        this.editMode = $('.sfPageEditor');

        if (this.sliderWrapper.length && !this.editMode.length) {
            this.init();
        }
    }
    
    init() {
        let self = this;
        
        this.sliderWrapper.each(function() {
            let slider = $(this).find('.slider-callout-items');
            let totalItems = slider.find('.slider-callout-item').length;
            let pausePlayBtn = $(this).find('.pause-play');
            let dotsWrapper = $(this).find('.dots-wrapper');
            
            if (totalItems > 1) {
                // initialize slick slider
                slider.slick({
                    dots: true,
                    appendDots: dotsWrapper,
                    arrows: false,
                    lazyLoad: 'ondemand',
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    fade: true,
                    autoplay: true,
                    autoplaySpeed: 7000,
                    pauseOnHover: false
                });
    
                // Handle if pause/play icon was clicked
                pausePlayBtn.on('click', (event) => {
                    event.preventDefault();
                    self.pausePlay(slider);
                });
            }
            else {
                $(this).find('.slider-controls').hide();
                $(this).find('.slider-callout-item').addClass('only-one-slide'); // helps get pulse animation to still load
            }
        });    

    }

    pausePlay(slider) {
        let pausePlayBtn = slider.siblings('.slider-controls').find('.pause-play');

        if (pausePlayBtn.hasClass('paused')) {
            slider.slick('slickPlay');
        }
        else {
            slider.slick('slickPause');
        }
        pausePlayBtn.toggleClass('paused').blur().find('.fas').toggleClass('fa-pause fa-play');
    }
}

class SimpleForm {

    constructor() {
        this.selectFields = $('select[data-sf-role="dropdown-list-field-select"]');
        this.submitBtn = $('.simple-form-callout .sf-SubmitButton');

        if (this.selectFields.length || this.submitBtn.length) {
            this.init();
        }
    }

    init() {
        
        // add disabled attribute to first item of any select
        if (this.selectFields.length) {
            this.addDisabledToSelects();
        }
        
        // Handle when submit is clicked
        this.submitBtn.on('click', (event) => {
            this.thankYouMessage(event);
        });
    }
    
    addDisabledToSelects() {
        this.selectFields.each(function() {
            $(this).find('option:eq(0)').prop('disabled', true);
        });
        
    }

    // after form is submitted, thank you message replaces form so scroll up so it's visible
    thankYouMessage(event) {
        const form = $(event.currentTarget).closest('.simple-form-callout');

        setInterval(function() {
            
            if (!form.hasClass('thank-you')) {
                $('html, body').animate({ scrollTop: form.offset().top}, 1000);
                form.addClass('thank-you');
            }
        }, 500);
    }
}

class SocialShare {

    constructor() {
        this.shareIcons = $('.social-share');

        if (this.shareIcons.length) {
            this.init();
        }
    }

    init() {
        const fb = $('.share-facebook');
        const lk = $('.share-linkedin');
        const tw = $('.share-twitter');
    
        const url = encodeURIComponent(window.location.href);
    
        fb.attr('href', `https://www.facebook.com/sharer/sharer.php?u=${url}`);
        lk.attr('href', `https://www.linkedin.com/shareArticle?mini=true&url=${url}`);
        tw.attr('href', `https://twitter.com/intent/tweet?url=${url}`);

        // need to find instagram

    }

}

class SolutionsLinks {

    constructor() {
        this.solutionsSelect = $('.solutions-select');
        this.selectTitle = $('.solutions-links .select-title');
        this.selectDropdown = $('.solutions-links .select-dropdown');
        this.selectOptions = $('.solutions-links .select-option');
        this.goBtn = $('.solutions-links .go-btn');

        if (this.solutionsSelect.length) {
            this.init();
        }
    }

    init() {

        // Handler to Toggle opening select box
        this.selectTitle.on('click', (event) => {
            event.preventDefault();
            this.toggleSolutionsDropdown();
        });

        // handle if user clicks outside of select box close it
        $(document).on('click', (event) => {                    
            if (!$(event.target).closest('.solutions-links').length) {
                this.closeDropdown();
            }
         }); 

        // Handler when select option was chosen
        this.selectOptions.on('click', (event) => {
            event.preventDefault();
            this.optionSelected(event);
        });
        
    }

    toggleSolutionsDropdown() {
        if (!this.solutionsSelect.hasClass('open')) {
            this.openDropdown();
        }
        else {
            this.closeDropdown();
        }
    }

    openDropdown() {
        this.solutionsSelect.addClass('open');
        this.selectDropdown.slideDown(300);
        this.selectTitle.find('.arrow').removeClass('fa-chevron-down').addClass('fa-chevron-up');
    }
    
    closeDropdown() {
        this.solutionsSelect.removeClass('open');
        this.selectDropdown.slideUp(300);
        this.selectTitle.find('.arrow').removeClass('fa-chevron-up').addClass('fa-chevron-down');
    }

    optionSelected(event) {
        const url = $(event.currentTarget).attr('href');
        const text = $(event.currentTarget).html();
        const aria = $(event.currentTarget).attr('aria-label');

        this.solutionsSelect.addClass('selected');
        this.selectTitle.attr('aria-label', aria).find('.select-title-text').html(text);
        this.goBtn.attr('href', url);
        this.closeDropdown();
    }

    
}

class SpeedBump {

    constructor() {

        // dom elements
        this.speedbumpLink = $('.speedbump-link');
        this.acceptBtn = '';
        this.denyBtn = '';
        this.speedbumpModal = '';
        this.message = '';
        this.url = '';

        if (this.speedbumpLink.length) {
            this.init();            
        }

    }

    init() {
	    
        // if ".speedbump-link" is clicked, pull message from data attribute and display in a modal
        this.speedbumpLink.on('click', (event) => {
            event.preventDefault();
            
            this.message = $(event.currentTarget).attr('data-redirect-message');            
            this.url = $(event.currentTarget).attr('href');
			
			//Get default text if one is not provided
			if(!this.message) {
				this.message = $('meta[name=speedbump-message]').attr('content');
			}
			
            // create modal with message
            this.createSpeedBumpModal();
            this.bindButtons();

            // Handle when Close is clicked
            this.denyBtn.on('click', (event) => {
                this.returnToSite(event);
            });

            // Handle when Accept is clicked
            this.acceptBtn.on('click', (event) => {
                this.allowLink(event);
            });
            
        });

    }

    bindButtons() {
        this.acceptBtn = $('.speedBumpModal .acceptBtn');
        this.denyBtn = $('.speedBumpModal .denyBtn');
        this.speedbumpModal = $('.speedBumpModal');
    }

    createSpeedBumpModal() {
        $('body').prepend('<div class="speedBumpModal"><div class="modalBox"><div class="modalHeader"><div class="title">External Link Disclaimer</div></div><div class="icon"><img src="/ResourcePackages/ST/library/img/logo.svg" alt="Georgia United Credit Union Logo" /></div><div class="notice">'+this.message+'</div><div class="ctas"><a href="'+this.url+'" class="acceptBtn btn btn-tertiary" target="_blank">Accept</a><a href="#" class="denyBtn btn btn-quaternary">Return To Site</a></div></div></div>');        
    }


    returnToSite(event) {     
        event.preventDefault();
        this.speedbumpModal.remove();
    }

    allowLink() {
        this.speedbumpModal.remove();
    }

}

class Testimonials {

    constructor() {
        this.slider = $('.testimonials-slider .testimonials');
        this.editMode = $('.sfPageEditor');

        if (this.slider.length && !this.editMode.length) {
            this.init();
        }
    }

    init() {

        // initialize slick slider
        this.slider.slick({
            dots: false,
            arrows: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: false,
            prevArrow: '<button class="slick-prev" aria-label="click to go to previous slide"><svg xmlns="http://www.w3.org/2000/svg" width="20.161" height="54.374" viewBox="0 0 20.161 54.374"><g class="slider-arrow-left" transform="translate(1164.273 1146.486) rotate(180)"><line class="Line_9" data-name="Line 9" x2="17.385" y2="25.799" transform="translate(1145.5 1093.5)"/><line class="Line_10" data-name="Line 10" y1="25.799" x2="17.385" transform="translate(1145.5 1119.299)"/></g></svg></button>',
            nextArrow: '<button class="slick-next" aria-label="click to go to next slide"><svg xmlns="http://www.w3.org/2000/svg" width="21.396" height="58.04" viewBox="0 0 21.396 58.04"><g class="slider-arrow-right" transform="translate(-1144.112 -1092.112)"><line class="Line_9" data-name="Line 9" x2="18.62" y2="27.632" transform="translate(1145.5 1093.5)"/><line class="Line_10" data-name="Line 10" y1="27.632" x2="18.62" transform="translate(1145.5 1121.132)"/></g></svg></button>'
        });  
    }
}

/* Site Specific Javascript Component Imports */



const MainScripts = (() => {

	// Shared
	if (typeof svg4everybody == 'function') { svg4everybody(); }
	PolyfillHelpers.main();

	/*  Site Specific javascript modules  */
	ScrollToTop.main();	
	const imageLoader = new ImageLoader();
	imageLoader.initialize(); // lazy load

	new Accordions();
	new Alerts();
	new ComparisonCards();
	new FeaturedResources();
	new FeaturedRates();
	new FeaturedStats();
	new FloatingFormLabel();
	new HeaderSearch();
	IsElementInView.main();
	new Login();
	new Menu();
	new ResponsiveDatatables();
	new SliderCallout();
	new SimpleForm();
	new SocialShare();
	new SolutionsLinks();
	new SpeedBump();
	new Testimonials();

	if ( $('.location-search').length ) {
		Locations.displayLocations();
	}

	// check for thank you message
	if ( $('.form-submit-result').length ) {
		// scroll to message
		$('html, body').scrollTop($('.form-submit-result').offset().top);
	}

	// Update iframe attributes and reload iframe to fix blocked content
	document.addEventListener('DOMContentLoaded', (function FixYoutubeWatchLinks() {
		var iframes = document.getElementsByTagName('iframe'); 
		for (var i = 0; i < iframes.length; i++) { 
			if (iframes[i].hasAttribute('src') &&  iframes[i].src.includes('https://www.youtube.com')) {
				iframes[i].setAttribute('referrerpolicy', 'no-referrer');
				var sandboxval = iframes[i].hasAttribute('sandbox') ? iframes[i].getAttribute('sandbox') : "" ; 
				sandboxval += " allow-scripts allow-same-origin allow-popups-to-escape-sandbox"; 
				iframes[i].setAttribute('sandbox', sandboxval);
				iframes[i].setAttribute('src', iframes[i].src);
			}
		}
	}));
})();
